import { SiteMutationLayout } from '@bbx/common/components/layouts/SiteMutationLayout'
import { BapStartPageUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { dummyAlladsCategoryId, dummyGiweawayCategoryId } from '@bbx/common/lib/constants'
import { StoryblokBapStartpage } from '@bbx/common/types/storyblok/StoryblokStartpage'
import { getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { Category } from '@bbx/search-journey/common/Category'
import { NavigatorId } from '@bbx/search-journey/common/Navigators'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { StartpageSeoMetaTags } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/StartpageSeoMetaTags'
import { BapStartPageContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/start-browse-pages/BapStartPageContainer'
import { removeAdsAndUnneededNavigators } from '@bbx/search-journey/sub-domains/search/lib/removeAdsAndUnneededNavigators'
import { ISbStoryData } from '@storyblok/react'
import CatDealershop from '@wh-components/icons/CatDealershop'
import CatFree from '@wh-components/icons/CatFree'
import { getMetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagApiClient'
import { MetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagCache'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { ScrollContainerProvider } from '@wh/common/chapter/components/ScrollContainerProvider/ScrollContainerProvider'
import { VERTICAL_LINKS_HEIGHT } from '@wh/common/chapter/components/VerticalLinks/VerticalLinks'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { isClient } from '@wh/common/chapter/lib/commonHelpers'
import { usePageViewForPageWithHardcodedTaggingData } from '@wh/common/chapter/lib/tagging/usePageViewForPageWithHardcodedTaggingData'
import { concatPathWithQueryParams } from '@wh/common/chapter/lib/urlHelpers'
import { OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { getStoryWithOptionalPreview } from '@wh/common/cms/api/storyblokApiClient'
import { StoryblokBubbleList } from '@wh/common/cms/components/bloks/StoryblokBubbleList'
import { StoryblokPictureWithBlueTextBottom } from '@wh/common/cms/components/bloks/StoryblokPictureWithBlueTextBottom'
import { GetStaticProps, NextPage } from 'next'
import React, { Fragment } from 'react'

interface PopularEntryArea {
    categories?: StoryblokPictureWithBlueTextBottom
    brands?: StoryblokBubbleList
}

interface BapStartPageProps {
    initialSearchResult: SearchResult
    metaTagInfo: MetaTagInfo
    popularEntryArea: PopularEntryArea | null
}

const SEO_PAGE_NAME = 'bap'

const BapStartPage: NextPage<BapStartPageProps> & { Layout: typeof Layout } & { hasStaticProps: boolean } & OptionalPageOptions = ({
    initialSearchResult,
    metaTagInfo,
    popularEntryArea,
}) => {
    usePageViewForPageWithHardcodedTaggingData('vertical_home', {
        tmsData: {
            vertical_id: '5',
            vertical: 'Marktplatz',
        },
    })

    if (!initialSearchResult) {
        return <ErrorPage statusCode={500} />
    }

    const giveAwayCategory: Category = {
        label: 'Zu verschenken',
        webLink: '/iad/kaufen-und-verkaufen/zu-verschenken/',
        categoryTreeId: dummyGiweawayCategoryId,
        Icon: CatFree,
        taggingEvent: 'vertical_home_giveaway_click',
    }

    const showAllCategory: Category = {
        webLink: concatPathWithQueryParams(staticRelativeCanonicals.BapResultList, { srcType: 'vertical-home', srcAdd: 'allads' }),
        Icon: CatDealershop,
        categoryTreeId: dummyAlladsCategoryId,
        label: 'Alle Anzeigen',
        taggingEvent: 'vertical_home_allads_click',
    }

    return (
        <Fragment>
            <BapStartPageUserZoom />
            <StartpageSeoMetaTags
                verticalId="BAP"
                metaTagInfo={metaTagInfo}
                seoPageName={SEO_PAGE_NAME}
                nrOfAdvertsFromSearch={initialSearchResult.rowsFound}
            />
            <ScrollContainerProvider additionalTopThreshold={VERTICAL_LINKS_HEIGHT} scrollElement={isClient() ? window : null}>
                <BapStartPageContainer
                    initialSearchResult={initialSearchResult}
                    extraCategories={[giveAwayCategory, showAllCategory]}
                    baseUrl={staticRelativeCanonicals.BapResultList}
                    pageTitle="Der größte Marktplatz Österreichs"
                    categoryAreaTitle="Alle Kategorien"
                    popularCategories={popularEntryArea?.categories}
                    popularBrands={popularEntryArea?.brands}
                />
            </ScrollContainerProvider>
        </Fragment>
    )
}

BapStartPage.Layout = SiteMutationLayout
BapStartPage.hasStaticProps = true
BapStartPage.verticalLinksType = 'sticky'

export const getStaticProps: GetStaticProps<BapStartPageProps> = async (context) => {
    const initialSearchResult = await getSeoSearchResult(`/kaufen-und-verkaufen/marktplatz`)

    const metaTagInfo = await getMetaTagInfo(SEO_PAGE_NAME)
    const filteredSearchResult = filterSearchResult(initialSearchResult)

    let bapStory: { story: ISbStoryData<StoryblokBapStartpage> } | undefined

    // time is a variable to be able to change it depending on the storyblok request
    let revalidate = 3600

    try {
        bapStory = await getStoryWithOptionalPreview('168077713', context.previewData)
    } catch (error) {
        console.error('error fetching bap entrypoint data from storyblok', error)
        // if there is an error with the storyblok api request, next.js should try to rebuild the site in 3min
        revalidate = 180
    }

    let popularEntryArea: PopularEntryArea | null = null

    if (bapStory) {
        const widgets = bapStory.story.content.widgets
        const categoryWidget = widgets.filter((widget) => widget?.component === 'pictureWithBlueTextBottom')[0] as
            | StoryblokPictureWithBlueTextBottom
            | undefined
        const brandsWidget = widgets.filter((widget) => widget?.component === 'bubbleList')[0] as StoryblokBubbleList | undefined
        popularEntryArea = {
            categories: categoryWidget,
            brands: brandsWidget,
        }
    }

    return {
        props: {
            initialSearchResult: filteredSearchResult,
            metaTagInfo: metaTagInfo,
            popularEntryArea: popularEntryArea,
        },
        revalidate: revalidate,
    }
}

/* strips some data - adverts and unneeded navigators - decreases the size of the static html+json. */
const filterSearchResult = (searchResult: SearchResult): SearchResult => {
    const neededNavigators: NavigatorId[] = ['category', 'keyword']

    return removeAdsAndUnneededNavigators(searchResult, neededNavigators)
}

export default BapStartPage
